export default function SpecialsBanner() {
    return (
        <div className="specials-banner">
            <div className="inner">
                <span className="text">
                    LAST UNIT REMAINING! INQUIRE TODAY.
                </span>
            </div>
        </div>
    );
}